export const filterNavConfigByRole = (navConfig, role, roles) => {
    let permissions = [];
    roles?.forEach((el) => {
        if (el?.permissions?.length > 0) {
            el?.permissions?.forEach((perm) => {
                permissions.push(perm?.action + '_' + perm?.entity);
            });
        }
    });
    const el = navConfig
        ?.map((item) => {
        const filteredItems = item?.items?.filter((subItem) => {
            const newSubItem = {
                ...subItem,
            };
            if (newSubItem?.author?.includes(role)) {
                if (newSubItem?.children) {
                    newSubItem.children = newSubItem.children.filter((el) => el?.author?.includes(role));
                    return newSubItem.children.length > 0;
                }
                return true;
            }
            return false;
        });
        const filtered = filteredItems?.filter((el) => {
            if (el?.entity) {
                if (!(permissions?.includes('all_' + el?.entity) ||
                    (permissions?.includes('read_' + el?.entity) &&
                        permissions?.includes('create_' + el?.entity)))) {
                    return false;
                }
            }
            return true;
        });
        return {
            ...item,
            items: filtered,
        };
    })
        ?.filter(Boolean);
    return el;
};
